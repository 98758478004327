.Loading {
  z-index: 3;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingWidth {
  width: 120px !important;
  height: 120px !important;
}
