.Teamwork {
  .slide{
    opacity: 0.3;
    padding: 10px;
    pointer-events: none;
  }

  .slide.selected{
    opacity: 1 !important;
    pointer-events: all;
  }

  .carousel .control-next.control-arrow:before{
    border-left: 12px solid rgba(0, 0, 0, 0.56);
  }

  .carousel .control-prev.control-arrow:before{
    border-right: 12px solid rgba(0, 0, 0, 0.56);
  }

  .carousel.carousel-slider .control-arrow{
    background: transparent;
    top: 10px;
    width: 14.5%;
    opacity: 1;
  }

  @media (max-width: 767px) {
    .carousel.carousel-slider .control-arrow{
      width: 9.5%;
    }
  }

  /* Estilos para los dots de navegación */
  .carousel-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px; /* Ajusta el margen según sea necesario */
  }

  .dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #888; /* Color de los dots inactivos */
    margin: 0 5px; /* Espaciado entre los dots */
    cursor: pointer;
  }

  .dot.active {
    background-color: #333; /* Color del dot activo */
  }

  .hero{
    margin-bottom: -1%;
  }
  
  .hero video, .hero img{
    position: absolute;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .canvas{
    opacity: .7;
    width: 100%;
    height: 100vh;
  }

  .imagen{
    width: 90%;
  }
}
