@import "../variables.scss";

.row {
  --bs-gutter-x: 0 !important;
}

.facebook{
  display: flex !important;
  justify-content: flex-end;
}

.telefono{
  display: flex !important;
  justify-content: center;
}

.labLogo {
  width: 50% !important; 
}

.telLogo {
  width: 65% !important;
}

.faceLogo {
  width: 65% !important;
  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  .labLogo {
    width: 60% !important;
  }

  .faceLogo {
    width: 75% !important; 
  }
  
  .telLogo {
    width: 75% !important;
  }
}

@media (max-width: 992px) {
  .labLogo {
    width: 70% !important;
  }

  .faceLogo {
    width: 85% !important; 
  }
  
  .telLogo {
    width: 85% !important;
  }
}

@media (max-width: 767px) {
  .labLogo {
    width: 30% !important;
  }

  .faceLogo {
    width: 45% !important; 
  }
  
  .telLogo {
    width: 45% !important;
  }

  .logo{
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .telefono{
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .facebook{
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .labLogo {
    width: 40% !important;
  }

  .faceLogo {
    width: 55% !important; 
  }
  
  .telLogo {
    width: 55% !important;
  }

  .logo{
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .telefono{
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .facebook{
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .labLogo {
    width: 55% !important;
  }

  .faceLogo {
    width: 70% !important; 
  }
  
  .telLogo {
    width: 70% !important;
  }

  .logo{
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .telefono{
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .facebook{
    display: flex;
    justify-content: center;
  }
}