.InfoEmpresa{
  .tituloServicio{
    font-size: 2rem !important;
  }

  .titulo{
    font-size: 20px !important;
  }

  .texto{
    font-size: 16px !important;
  }

  .swiper-button-next, .swiper-button-prev{
    color: rgba(0, 0, 0, 0.70);;
  }

  /* Estilos para los dots de navegación */
  .carousel-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px; /* Ajusta el margen según sea necesario */
  }

  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #888; /* Color de los dots inactivos */
    margin: 0 5px; /* Espaciado entre los dots */
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    background-color: #333; /* Color del dot activo */
  }  
}