.Header{
  .navmenu {
    .logo{
      width: 175px;
    }
  }

  .navmenu.scroll {
    background-color: #ef979d !important;
  }
  
  .navmenu {
    &__nav-items {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

.HeaderMain{
  .navmenu {
    .logo{
      width: 175px;
    }
  
    .servicio{
      text-decoration: none;
      color: rgba(255, 255, 255, 0.55);
    }
  
    .servicio:hover{
      text-decoration: none;
      color: rgba(255, 255, 255, 0.75);
    }
  
    &__nav-items {
      width: 100%;
      display: flex !important;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  
  .navmenu.scroll {
    background-color: #ef979d !important;
  }
  
  .hero{
    margin-bottom: -1%;
  }
  
  .hero video{
    position: absolute;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .canvas{
    opacity: .7;
    width: 100%;
    height: 100vh;
  }
}