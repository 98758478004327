.objetoSocial{
  .card{
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    border: 1px solid #f0878b;
  }

  .icon{
    width: 20%;
    margin-top: 1rem;
  }  
}