.equipo{  
  .fondos{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 16px;
  }

  @media(max-width: 359px){
    .fondos{
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
  }

  .span{
    grid-column: span 2;
  }

  .row{
    grid-row: span 2;
  }

  .fondo {
    color: #fff;
    position: relative;
    overflow: hidden;
    background: #000000;    
    height: max-content;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .fondo .texto p{
      font-size: 16px !important;
    }
  }

  @media (max-width: 767px) {
    .fondos{
      justify-content: center !important;
    }
  }

  @media (max-width: 365px) {
    .fondo .titulo p{
      font-size: 12px !important;
    }

    .fondo .texto p{
      font-size: 10px !important;
    }

    .fondo figcaption{
      padding: 2em 2em;
    }
  }

  .fondo * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .fondo img {
    opacity: 1;
    width: 100%;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
  }
  .fondo figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3em 3em;
    width: 100%;
    height: 100%;
  }
  .fondo figcaption::before {
    position: absolute;
    top: 50%;
    right: 30px;
    bottom: 50%;
    left: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    content: '';
    opacity: 0;
    background-color: #ffffff;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }
  .fondo .titulo,
  .fondo .texto {
    margin: 0 0 5px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s,-webkit-transform 0.35s,-moz-transform 0.35s,-o-transform 0.35s,transform 0.35s;
  }
  .fondo .titulo {
    font-weight: 300;
    -webkit-transform: translate3d(0%, 50%, 0);
    transform: translate3d(0%, 50%, 0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  
  .fondo .texto {
    font-weight: 200	;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    text-align: justify;
  }
  .fondo:hover img {
    opacity: 0.35;
  }
  .fondo:hover figcaption .titulo {
    opacity: 1;
    -webkit-transform: translate3d(0%, 0%, 0);
    transform: translate3d(0%, 0%, 0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  .fondo:hover figcaption .texto {
    opacity: 0.9;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }
  .fondo:hover figcaption::before {
    background: rgba(255, 255, 255, 0);
    top: 30px;
    bottom: 30px;
    opacity: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }
}
