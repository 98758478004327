.TitleIzquierda{
  position: relative;
  overflow: hidden;
  text-align: start;    

  .gif{
    width: 10%;
    position: absolute; 
    top: calc(5% + 15px);
  }

  .capa-superior {
    position: absolute;
    top: 35%;
  }

  .capa-inferior{
    transform: matrix(1, 0, -0.78129, 1, -50, 0); 
    width: 65%;
    background-color:#f0878b;
    padding:60px 0 60px;
  }
  
  .anonce-block__parts{
    position:absolute;
    top:0;
    left:calc(62% + 15px);
    height:100%;
    font-size:0
  }
  
  .anonce-block__part:nth-child(1){
    max-width:540px;
    position:absolute;
    height:100%;
    z-index:10;
    left:-6px;
    background:#f5a0a2;
    top:0;
    transform:skewX(-38deg);
    width:145px
  }
  
  .anonce-block__part:nth-child(2){
    position:absolute;
    height:100%;
    z-index:10;
    left:138px;
    background:#f5a0a2;
    top:0;
    transform:skewX(-38deg);
    width:95px
  }
  
  .anonce-block__part:nth-child(3){
    position:absolute;
    height:100%;
    z-index:10;
    left:232px;
    background:#ffb6b8;
    top:0;
    transform:skewX(-38deg);
    width:68px
  }
  
  .anonce-block__body{
    max-width:60%
  }
  
  .anonce-block__title{
    color:#fff;
    font-size:28px;
    letter-spacing:.4px;
    margin-bottom:13px
  }

  @media (min-width:1400px) and (max-width:1599px){
    .gif{
      width: 8%;
      top: 35%;
    }
    .anonce-block__title{
      position: relative;
      left: 10%;
    }
  }  

  @media (min-width:1300px) and (max-width:1399px){
    .gif{
      width: 8%;
      top: 35%;
    }
  }  

  @media only screen and (max-width:1299px){
    .anonce-block__part:nth-child(1){
      left:-8px
    }
    .anonce-block__part:nth-child(2){
      left:136px
    }
    .anonce-block__part:nth-child(3){
      left:230px
    }

    .anonce-block__title{
      position: relative;
      left: 10%;
    }    
  }

  @media (min-width:1024px) and (max-width:1299px){
    .gif{
      top: 32%;
    }
  }

  @media (min-width:768px) and (max-width:1023px){
    .gif{
      top: 35%;
    }
  }

  @media (min-width:650px) and (max-width:767px){
    .gif{
      top: 35%;
      width: 15% !important;
    }
  }

  @media (min-width:650px) and (max-width:1299px){
    .capa-inferior{
      width: 75%;
    }

    .anonce-block__title{
      font-size: 24px;
    }    
  }  

  @media (min-width:530px) and (max-width:649px){
    .capa-inferior{
      width: 80%;
    }

    .anonce-block__title{
      font-size: 24px;
      position: relative;
      left: 5%;
    }

    .gif{
      top: 0%;
      width: 20%;
    }
  }  

  @media (min-width:370px) and (max-width:529px){
    .anonce-block__part:nth-child(1){
      left: 50px;
    }

    .capa-inferior{
      width: 100%;
    }

    .anonce-block__body{
      max-width:70% !important
    }

    .anonce-block__title{
      font-size: 20px;
      position: relative;
      left: 5%;
    }

    .capa-superior{
      top: 30%;
    }

    .gif{
      top: 0%;
      width: 25%;
    }
  }  

  @media (max-width:369px){  
    .capa-inferior{
      width: 120%;
    }

    .anonce-block__title{
      font-size: 16px;
      position: relative;
      left: 10%;
    }

    .gif{
      top: 0%;
      width: 25%;
    }
  }  
}