.title {
  &__regresar {
    width: 100%;
    display: block;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    margin: 0;
    color: #212529;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:hover {
      color: #212529;
    }
  }
}
